import React from "react"
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <StaticQuery
      query={graphql`
        query PlaceholderQuery {
          placeholderImage: file(relativePath: { eq: "luke_placeholder.PNG"}) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data =>
        <Img
          fluid={data.placeholderImage.childImageSharp.fluid}
          imgStyle={{
            height: '610px'
          }}
        />
      }
    />
  </Layout>
)